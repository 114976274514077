@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border-color: #222e40;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111827;
  color: #f8fafc;
}

.bg {
  background-color: #111827;
}

.custom-content-bg {
  background-color: #111727;
}

a {
  color: white !important;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: #8b5cf6;
}

.custom-viollet-bg {
  background-color: #8b5cf6;
}
.custom-viollet-cl {
  color: #8b5cf6;
}

.main-bg {
  background-color: #020817;
}

.main-conten-bg {
  background: linear-gradient(80deg, rgba(17, 24, 39, 1), rgb(49, 27, 71));
}

.custom-text-nowrap {
  overflow: hidden;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

/* Color the inactive stars red */
.ant-rate-star:not(.ant-rate-star-full) .ant-rate-star-first,
.ant-rate-star:not(.ant-rate-star-full) .ant-rate-star-second {
  color: silver;
}

/* Specifically color the second half of the half stars silver */
.ant-rate-star.ant-rate-star-half .ant-rate-star-second {
  color: silver;
}

/* Specifically color the first half of the half stars yellow */
.ant-rate-star.ant-rate-star-half .ant-rate-star-first {
  color: yellow;
}

/* Ensure that empty stars are always visible */
.ant-rate-star-zero .ant-rate-star-first,
.ant-rate-star-zero .ant-rate-star-second {
  color: rgba(0, 0, 0, 0.25); /* Default Ant Design empty star color */
}

.custom-tabs .ant-tabs-nav::before {
  border-bottom: none !important;
}

/* Faol (active) holat uchun */
.custom-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff !important;
}

/* Faol bo'lmagan (inactive) holat uchun */
.custom-tabs .ant-tabs-tab {
  color: grey; /* Istagan rangni tanlashingiz mumkin */
}
.custom-tabs .ant-tabs-ink-bar {
  display: none !important;
}

.custom-collapse .ant-collapse-content-box {
  padding: 0 !important;
  padding-block: 0 !important;
}

.ant-collapse-content-box {
  padding: 0 16px 16px 16px !important;
}

.custom-selection .ant-select-selector {
  background-color: #414c5d73 !important;
  border: none !important;
  color: white !important;
}

.custom-selection .ant-select-selection-placeholder {
  color: #a9a9a9 !important;
}
