.custom_placeholder::-webkit-input-placeholder {
  color: #d6dcff73 !important;
}
:-moz-placeholder {
  color: #d6dcff73 !important;
  opacity: 1;
}
.custom_placeholder::-moz-placeholder {
  color: #d6dcff73 !important;
  opacity: 1;
}
:-ms-input-placeholder {
  color: #d6dcff73 !important;
}
.custom_placeholder::-ms-input-placeholder {
  color: #d6dcff73 !important;
}

.custom_placeholder::placeholder {
  color: #d6dcff73 !important;
}
