/* CSS */
.modal-container {
    max-width: 90%; /* Modalning maksimal o'lchami (masalan, 90% ekran eni) */
  }
  
  /* Ekran o'lchamiga bog'liq moslashtirishlar */
  @media screen and (min-width: 640px) {
    .modal-container {
      max-width: 600px; /* Telefon uchun modalning maksimal o'lchami */
    }
  }
  
  @media screen and (min-width: 768px) {
    .modal-container {
      max-width: 768px; /* Planshetlar uchun modalning maksimal o'lchami */
    }
  }
  
  @media screen and (min-width: 1024px) {
    .modal-container {
      max-width: 1024px; /* Notebook uchun modalning maksimal o'lchami */
    }
  }
  
  @media screen and (min-width: 1280px) {
    .modal-container {
      max-width: 1280px; /* Macbook uchun modalning maksimal o'lchami */
    }
  }
  